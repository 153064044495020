import React from 'react';
import  HomeButton from "../../../Components/HomeButton"
import {
    Content,
    Header,
    ProjectTitle,
    ProjectSubtitle,
    ProjectSection,
    Date,
    Image,
} from "./elements"

function XYPlotter() {
    return (
        <>
            <HomeButton/>
            <Header>x-y plotter</Header>
            <Content>
                <div>
                    <ProjectTitle>X-Y plotter</ProjectTitle>
                    <ProjectSubtitle>"Speil man ikke kan se seg selv i"</ProjectSubtitle>
                    <ProjectSection>Mål</ProjectSection>
                    <p>Prosjektet går ut på å lage en X-Y plotter, som skal bevege en lampe. Så skal jeg bruke face tracking, så lampen alltid er foran ansiktet. Når lampen plasseres bak et toveisspeil får man den effekten at det er umulig å se seg selv.</p>
                    <h4>Utstyr</h4>
                    <ul>
                        <li>2x NEMA 14 Stepper motor</li>
                        <li>2x A4988 Stepper driver</li>
                        <li>Arduino Uno</li>
                        <li>12V 1A strømforsyning</li>
                        <li>Arduino CNC shield</li>
                        <li>Noen 3d-printede deler</li>
                        <li>Lite led lys</li>
                        <li>4x alt for tunge stålstenger</li>
                        <li>Ikea billedramme med toveisspeilfolie (ekte toveisspeil er ekstremt dyrt)</li>
                        <li>Breadboard osv</li>
                    </ul>
                    <h4>Progress</h4>
                    <Date>2023.02.18</Date>
                    <p>
                        Alle delene er endelig på plass, og arbeidet er i gang. Jeg jobber med å få steppermotorene til å bevege seg, og innser at strømforsyningen (5V) ikke har høy nok spenning (trenger 12).
                        Vi er altså i veldig tidlig startfase, her er bilde av Arduino koblet til motor :) 
                    </p>
                    <Image src="imgs/projects/xyplotter/xyplotter1.jpg" alt="xyplotter1" className="img"/>
                    <Date>2023.02.24</Date>
                    <p>
                        Mesteparten av programmeringen er ferdig, og jeg har fått til ansiktstracking med openCV. OpenCV la til en ny model i slutten av 2022 (YuNet), som er mye bedre enn Haar-Cascade, som er det jeg brukte tidligere. 
                        Posisjonen av midten av ansiktet blir nå sendt til Arduino, og logikken for å bevege stepperne er lagt inn, men ikke testet enda. 
                    </p>
                    <Image src="imgs/projects/xyplotter/xyplotter2.gif" alt="xyplotter2" className="gif"/>
                </div>
            </Content>
        </>
    );
}

export default XYPlotter;

import React from 'react'
import {
  ProjectLink,
  Image,
  Overlay,
  ProjectTitle,
  ProjectDescription
} from './elements'
export default function Pagelink(props) {
  return (
    <ProjectLink href={props.link}>
        <Image src={props.imgsrc} />
        <Overlay background_color={props.background_color}>
            <ProjectTitle>
                {props.title}
            </ProjectTitle>
            <ProjectDescription>
                {props.desc} <br/>
            </ProjectDescription>
        </Overlay>
    </ProjectLink>
  )
}

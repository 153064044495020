import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin: auto;
    font-family: "ABC Social", sans-serif;	
	font-size: 18px;
`;

export const Image = styled.img`
    width: 45%;
    margin: auto;
    display: block;
`;

export const Header = styled.h1`
    font-family: "ABC Social", "Nunito Sans", sans-serif;
    font-size: 4rem;
    font-weight: 900;
    padding: 0;
    color: #000;
    font-stretch: expanded;
    width: fit-content;
    margin: 5rem 0 2rem 15vw;
`;

export const ProjectTitle = styled.h2`
    font-size: 3em;
    font-family: "Catamaran", sans-serif;
    font-weight: 800;
    margin-bottom: 0;
`;

export const ProjectSubtitle = styled.h3`
    font-size: 1.5em;
    margin-top: 0;
    font-style: italic;
`;

export const ProjectSection = styled.h4`
    font-size: 1em;
    font-family: "ABC Social", sans-serif;
    font-weight: 800;
    margin: 0;
`;

export const Date = styled.h5`
    font-family: 'Courier New', Courier, monospace;
    margin: 0;
`;

export const ProjectWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 2rem;
    margin: 5rem auto;
    width: 70%;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 2rem;
    }
`
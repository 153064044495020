import styled from 'styled-components';


export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin: auto;
    padding-bottom: 2em;

    > .left {
        align-self: flex-start;
        text-align: left;
    }
    > .right {
        align-self: flex-end;
        text-align: right;
    }

`;

export const Image = styled.img`
    width: 55%;
    margin: 2em 0 1em 0;
`;

export const Title = styled.p`
    font-size: 1em;
    width: 50%;
    margin: 0;
    text-align: left;
    font-style: italic;
`;

export const Camera = styled.p`
    font-size: 1em;
    width: 50%;
    margin: 0;
`;

export const Film = styled.p`
    font-size: 1em;
    width: 50%;
    margin: 0;
`;
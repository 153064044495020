import React from 'react';
import {Content, Image, Title, Camera, Film} from './elements';
import  HomeButton from "../../Components/HomeButton"
import imageData from './imgs.json';
function Portfolio() {
    return (
        <>
            <HomeButton/>
            <Content>
                {imageData.map((img, index) => (
                    
                    <>
                        <Image key={index} src={"imgs/portofolio/" + img.filename} alt={img.title} className={index % 2 === 0 ? "left" : "right"}/>
                        {img.title ? <Title className={index % 2 === 0 ? "left" : "right"}>{img.title}</Title> : null}
                        {img.camera ? <Camera className={index % 2 === 0 ? "left" : "right"}>{img.camera}</Camera> : null}
                        {img.film ? <Film className={index % 2 === 0 ? "left" : "right"}>{img.film}</Film> : null}
                    </>
                ))}
            </Content>
        </>
    );
}

export default Portfolio;

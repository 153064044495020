import styled from "styled-components";
import AboutSection from "./Components/AboutSection";

export const Wrapper = styled.div`
    display: block;
    width: 70vw;
    margin: auto;    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
export const Hero = styled.p`
    font-family: "ABC Social", "Nunito Sans", sans-serif;
    font-size: 18pt;
    line-height: 1.4;
    max-width: 45ch;
    hyphens: auto;
    font-stretch: normal;
`

export const Header = styled.h1`
    font-family: "ABC Social", "Nunito Sans", sans-serif;
    font-size: 4rem;
    font-weight: 900;
    padding: 0;
    color: #000;
    font-stretch: expanded;
    width: fit-content;
    margin: 5rem 0 2rem 0;
`
export const ScrollHint = styled.a`
    position: absolute;
	bottom: 1rem;
	width: 100vw;
	display: inline-block;
    text-align: center;
    font-size: 1.5rem;
    color: #000;
	font-family: "ABC Social", "Nunito Sans", sans-serif;
	transform: skewX(-5deg);
    animation: bobbing 1.5s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
    @keyframes bobbing {
        from {line-height: 60px;}
	    to   {line-height: 50px;}
    }
    @media (max-width: 768px) {
        bottom: -5rem;
        width: 100vw;
    }
`
export const FadeContainer = styled.div`
    position: fixed;
    @media (max-width: 768px) {
        position: relative;
    }
`

export const SocialLinks = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 25ch;
    `

export const SocialLink = styled.a`
    display: inline-block;
    text-align: center;
    font-size: 2rem;
    color: #000;
	transition: 0.2s ease;
	&:hover {
        opacity: 0.5;
	}
`
export const Navigation = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 25ch;
    text-align: right;
    margin: 10rem 0;
`

export const NavLink = styled.a`
    font-family: "ABC Social", "Nunito Sans", sans-serif;
    font-size: 1.5rem;
    font-stretch: expanded;
    color: #000;
    text-decoration: none;
    transition: 0.2s ease;
    &:hover {
        color: #666;
    }
`

export const About = styled(AboutSection)``
import React from 'react';
import HomeButton from "../../Components/HomeButton"
import ProjectLink from './Components/ProjectLink'
import {
    Header,
    ProjectWrapper
} from "./elements"

function Projects() {
    return (
        <>
            <HomeButton/>
            <Header>prosjekter</Header>
            <ProjectWrapper>
                <ProjectLink
                    link="#/dustjacket"
                    imgsrc="/imgs/dustindex.png"
                    background_color="rgba(5, 112, 116, 0.5)"
                    title="dustjacket"
                    desc="Finn en ny bok å lese"
                />
                <ProjectLink
                    link="#/projects/xyplotter"
                    imgsrc="/imgs/raspberrypi.jpg"
                    background_color="rgba(55, 119, 74, 0.5)"
                    title="x-y plotter"
                    desc="Lite robotprosjekt på sida"
                />
                <ProjectLink
                    link="#/flow"
                    imgsrc="/imgs/flowindex.png"
                    background_color="rgba(114, 67, 158, 0.5)"
                    title="flowmodoro"
                    desc="Enkel studietimer med fokus på flyt"
                />
                <ProjectLink
                    link="https://youtu.be/LgIA1JOIouM"
                    imgsrc="/imgs/robotindex.jpg"
                    background_color="rgba(206, 127, 62, 0.5)"
                    title="circus robotics"
                    desc="Teaching a new dog old tricks"
                />
            </ProjectWrapper>
        </>
    );
}

export default Projects;

import React from 'react'
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import  HomeButton from "../../Components/HomeButton"

import {
  ResumeContainer,
  SectionTitle,
  Description,
  Section,
  Header,
  Divider,
  SectionItem,
  Subtitle,
  Date, 
  JobTitle
} from './elements'
export default function Resume() {
  return (
    <>
      <HomeButton/>
        <Header>
          saim iqbal
        </Header>
        <ResumeContainer>
          <Description>
          5. årsstudent i kybernetikk og robotikk. IT-engasjert, og interessert i
          maskinlæring, autonome systemer, robotikk og medisinsk kybernetikk.
          Mye teknisk kompetanse, og variert erfaring fra både verv og internship.
          Fulbright-stipendiat i 2023.
          </Description>
          <Divider/>
          <SectionTitle>
            Utdanning
          </SectionTitle>
          <Section>
            <Date>
              2023-08 - 2024-05
            </Date>
            <SectionItem>
              <Subtitle>
                Massachussets Institute of Technology
              </Subtitle>
              <JobTitle>
                Utveksling - Department of Mechanical Engineering
              </JobTitle>
              <Description>
              Tok fag innenfor robotikk, blant annet Bio-inspired Robotics, Robotic Manipulation og Underactuated Robotics. Fordyper meg også i bioteknologi, med fag som Mechanics of Living Systems og Neural Control of Movement.
              </Description>
            </SectionItem>
          </Section>
          <Section>
            <Date>
              2020-08 - 2025-06
            </Date>
            <SectionItem>
              <Subtitle>
                Norges teknisk-naturvitenskapelige universitet
              </Subtitle>
              <JobTitle>
                Master i kybernetikk og robotikk
              </JobTitle>
              <Description>
              Gjennom studiene på NTNU har jeg tilegnet meg kompetanse innen hvordan dynamiske systemer kan styres og overvåkes ved å kombinere matematikk, naturvitenskap, datateknologi og reguleringsteknikk. Skriver nå master innenfor biomedisinsk kybernetikk, om anvendelse av kybernetiske metoder for eksoskjelett for armen
              </Description>
            </SectionItem>
          </Section>
          <Section>
            <Date>
            2017-08 – 2020-06
            </Date>
            <SectionItem>
              <Subtitle>
              Lillestrøm videregående skole
              </Subtitle>
              <JobTitle>
              Studiespesialisering
              </JobTitle>
            </SectionItem>
          </Section>
          <Divider/>
          <SectionTitle>
            Arbeidserfaring
          </SectionTitle>
          <Section>
            <Date>
              2024-06 - 2024-08
            </Date>
            <SectionItem>
              <Subtitle>
                Vilje Bionics
              </Subtitle>
              <JobTitle>
                Summer Intern
              </JobTitle>
              <Description>
                Arbeidet med instrumentering og styring av eksoskjelett i Vilje Bionics. Jobbet selvstendig og hadde ansvar for å foreslå og implementere nye løsninger.
              </Description>
            </SectionItem>
          </Section> 
          <Section>
            <Date>
              2023-06 - 2023-08
            </Date>
            <SectionItem>
              <Subtitle>
                Zivid
              </Subtitle>
              <JobTitle>
                Summer intern
              </JobTitle>
              <Description>
              Fikk i oppgave å utvikle et produkt som skulle forbedre kundeopplevelsen. Utforsket forskjellige teknologier, og lagde et program med enkelt
              brukergrensesnitt. Implementerte en maskinlæringsalgoritme for å levere et innovativt produkt som var svært tilfredstillende. 
              </Description>
            </SectionItem>
          </Section>
          <Section>
            <Date>
              2022-06 – 2023-03
            </Date>
            <SectionItem>
              <Subtitle>
                NorgesGruppen Data
              </Subtitle>
              <JobTitle>
                Systemutvikler
              </JobTitle>
              <Description>
                Sommerjobb i NorgesGruppen Data. Jobbet agilt for å utvikle et program som automatiserte interne
                dataanalyser. Fungerte som fullstack-utvikler, og brukte blant annet Django, React, SQL og Azure for å lage
                programmet. Fikk i tillegg erfaring som scrum master. Fortsatte så min stilling fra sommer på deltid, arbeidet med et team for å gjøre programmet klart for
                produksjon.
              </Description>
            </SectionItem>
          </Section>
          <Section>
            <Date>
              2022-01 – 2022-06
            </Date>
            <SectionItem>
              <Subtitle>
                Norges teknisk-naturvitenskapelige universitet
              </Subtitle>
              <JobTitle>
                Læringassistent
              </JobTitle>
              <Description>
                Læringsassistent i emnet TDT4102 – Prosedyre- og objektorientert programmering. Rettet øvinger og veiledet
                studenter.
              </Description>
            </SectionItem>
          </Section>
          <Section>
            <Date>
              2020-06 – 2020-08
            </Date>
            <SectionItem>
              <Subtitle>
                Lørenskog kommune
              </Subtitle>
              <JobTitle>
                Sommerreporter
              </JobTitle>
              <Description>
              Formidlet kommunens sommertilbud til barn og unge gjennom nettsider og sosiale media. Ble opplært i god
              språkbruk, oppleve arbeid under diverse kriser, og fikk god erfaring med arbeid som del av et team.
              </Description>
            </SectionItem>
          </Section>
          <Section>
            <Date>
              2018-09 – 2020-06
            </Date>
            <SectionItem>
              <Subtitle>
                Akershus Kunstsenter
              </Subtitle>
              <JobTitle>
                Kunst-vlogger
              </JobTitle>
              <Description>
                Produserte 1-3 videoer i måneden, var ansvarlig for hele prosessen, fra idé til postproduksjon. Intervjuet
                aktuelle kunstnere, framhevet utstillingene ved kunstsenteret, og fikk nyttig medmenneskelig erfaring.
              </Description>
            </SectionItem>
          </Section>
          <Divider/>
          <SectionTitle>
            Annen erfaring
          </SectionTitle>
          <Section>
            <Date>
              04.02.2023–05.02.2023
            </Date>
            <SectionItem>
              <JobTitle>
                Hackathon - Elektronisk systemdesign
              </JobTitle>
              <Description>
                Vinner av Hackathonet «High-tech til besta», som gikk ut på å utvikle en ny teknologi som skal bedre
                eldreomsorgen. Min gruppe vant for en innovativ løsning som skulle forhindre fall.
              </Description>
            </SectionItem>
          </Section>
          <Section>
            <Date>
              01.04.2022–02.02.2023
            </Date>
            <SectionItem>
              <JobTitle>
                IT-gruppen i Nettverkdagene
              </JobTitle>
              <Description>
                Frontendutvikler for nettsiden til Nettverksdagene
              </Description>
            </SectionItem>
          </Section>
          <Section>
            <Date>
              01.03.2021–30.11.2021
            </Date>
            <SectionItem>
              <JobTitle>
              SoMe-produsent for UKA
              </JobTitle>
              <Description>
                Produserte videomateriell for promotering av kulturfestivalen UKA på sosiale medier.
              </Description>
            </SectionItem>
          </Section>
          <Section>
            <Date>
              01.08.2020–31.05.2021 
            </Date>
            <SectionItem>
              <JobTitle>
                Software-team i Shift Hyperloop
              </JobTitle>
              <Description>
                Programmerte et baseprogram for hyperloop-poden
              </Description>
            </SectionItem>
          </Section>
        </ResumeContainer>
    </>
  )
}

